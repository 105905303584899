import { useClickOutside } from '@zupr/hooks/ux'
import classnames from 'classnames'
import React, { forwardRef } from 'react'
import Tether from 'react-tether'

import '../../../scss/react/backoffice/dropdown.scss'
import '../../../scss/react/components/tether.scss'

const ClickOutsideDropdown = forwardRef(function ClickOutsideDropdown(
    { children, onClickOutside },
    ref
) {
    useClickOutside(ref, onClickOutside)
    return <div ref={ref}>{children}</div>
})

const Dropdown = ({
    children,
    open,
    attachment = 'top center',
    targetAttachment = 'bottom center',
    className = undefined,
    onClose = () => console.warn('tethered dropdown has no onClose function'),
    offset = '-10px 0',
}) => {
    if (React.Children.count(children) !== 2) {
        return <div>{children}</div>
    }

    return (
        <Tether
            attachment={attachment}
            targetAttachment={targetAttachment}
            className={classnames('zupr-dropdown', className)}
            offset={offset}
            constraints={[
                {
                    to: 'window',
                    pin: true,
                },
            ]}
            renderTarget={(ref) =>
                React.cloneElement(React.Children.toArray(children)[0], {
                    ref,
                })
            }
            renderElement={(ref) =>
                open && (
                    <ClickOutsideDropdown ref={ref} onClickOutside={onClose}>
                        {React.Children.toArray(children)[1]}
                    </ClickOutsideDropdown>
                )
            }
        />
    )
}

export default Dropdown
