import { t } from '@zupr/i18n'
import { Location } from '@zupr/types/fo'
import Link from 'next/link'
import { useCallback, useState } from 'react'

import ModalDialog from '../../../../shared/components/dialog'
import LocationMenu from '../../../screens/location/menu'

import CloseIcon from '../../../../../svg/cross.svg'
import Hamburger from '../../../../../svg/hamburger.svg'

import '../../../../../scss/react/frontoffice/site-menu.scss'

interface Props {
    location: Location
}

export const LocationMobileMenu = ({ location }: Props) => {
    const [open, setOpen] = useState(false)
    const handleClose = useCallback(() => setOpen(false), [])
    return (
        <>
            <button
                className="inline"
                aria-label={t('Menu')}
                onClick={() => setOpen(!open)}
            >
                <span>
                    <Hamburger />
                </span>
            </button>
            <ModalDialog isOpen={open} onClose={handleClose} position="left">
                <div className="dialog-header">
                    <Link href="/">
                        <a className="logo" onClick={handleClose}>
                            <h3>{location.title}</h3>
                        </a>
                    </Link>
                    <button
                        className="btn btn-micro btn-light menu-close"
                        type="button"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div className="mobile-menu">
                    <LocationMenu location={location} onClick={handleClose} />
                </div>
            </ModalDialog>
        </>
    )
}

export default LocationMobileMenu
