import { call } from '@zupr/api'
import { useRequest } from '@zupr/hooks/request-redux'
import {
    Field,
    FieldConfig,
    Form,
    FormLabels,
    UseFormData,
} from '@zupr/types/form'
import fieldError from '@zupr/validation'
import { get as dotGet, set as dotSet, isEmpty, isObject } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import useConfig from './config'

// cleans object and returns what is left over (null or {})
const cleanObject = (obj, empty = {}) => {
    if (!obj || !isObject(obj)) return obj
    Object.keys(obj).forEach((key) => {
        if (isObject(obj[key]) && !isEmpty(obj[key]))
            cleanObject(obj[key], null)
        if (isEmpty(obj[key])) delete obj[key]
    })
    return isEmpty(obj) ? empty : obj
}

interface FormFields {
    [name: Field['name']]: FieldConfig
}

export interface UseFormProps {
    url?: string
    pause?: boolean
    values?: Partial<UseFormData>
    fields?: FormFields
    onChange?: (data: Partial<UseFormData>) => void
    labels?: FormLabels
}

function useForm<T = any>(props: UseFormProps): Form<T> {
    const [url, setUrl] = useState<string | undefined>(props.url)
    const [isSaving, setSaving] = useState(false)
    const [isSaved, setSaved] = useState(false)
    const [changes, setChanges] = useState(props.values || {})
    const [fieldErrors, setFieldErrors] = useState({})
    const [formErrors, setFormErrors] = useState<string[]>([])

    if (!props.pause && !props.url) {
        throw new Error('Form requires url')
    }

    const {
        availableFields,
        requiredFields,
        action,
        getFieldConfig,
        ...options
    } = useConfig({
        url,
        fields: props.fields,
    })

    // action POST is create
    // action PUT is update
    const isNew = !action || action === 'POST'

    const [values, request, reexecute] = useRequest({
        url,
        pause: !url || isNew || props.pause,
    })

    const isLoading = request.fetching
    const isReady = !request.fetching && !options.fetching

    const getErrors = useCallback(
        (name) => dotGet(fieldErrors, name),
        [fieldErrors]
    )
    const getRemoveValue = useCallback((name) => dotGet(values, name), [values])
    const getChanged = useCallback((name) => dotGet(changes, name), [changes])

    const getValue = useCallback(
        (name) => {
            return getChanged(name) !== undefined
                ? getChanged(name)
                : getRemoveValue(name)
        },
        [getChanged, getRemoveValue]
    )

    // callback for value changes
    useEffect(() => {
        if (!isReady) return
        if (!props.onChange) return
        props.onChange({ ...values, ...changes })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onChange, values, changes])

    const setErrors = useCallback((name, error) => {
        setFieldErrors((errors) => {
            dotSet(errors, name, error)
            return cleanObject({ ...errors })
        })
    }, [])

    const setValue = useCallback(
        (name, value) => {
            // reset error for this field
            setErrors(name, null)

            // set value for this field
            setChanges((changes) => {
                dotSet(changes, name, value)
                return { ...changes }
            })

            // form isnt saved anymore
            if (isSaved) setSaved(false)
        },
        [isSaved, setErrors]
    )

    const getField = useCallback(
        (name) => {
            if (!availableFields.includes(name)) {
                console.warn(
                    `field ${name} is not an available field (yet)`,
                    availableFields
                )
            }
            const fieldConfig = getFieldConfig(name)

            // @TODO move this to where choices are used
            // add choices to parent (list)
            if (
                fieldConfig?.child &&
                fieldConfig?.child?.choices &&
                !fieldConfig?.choices
            ) {
                console.warn('child choices used in parent')
                fieldConfig.choices = fieldConfig.child.choices
            }

            const value = getValue(name)
            const errors = getErrors(name)
            const field: Field = {
                name,
                value,
                errors,
                error: errors?.join(', '),
                ...fieldConfig,
                setValue: (value) => setValue(name, value),
                setErrors: (errors) => setErrors(name, errors),
                isValid: () => {
                    const error = fieldError(fieldConfig || {}, value)
                    return !error
                },
            }
            return field
        },
        [
            availableFields,
            getErrors,
            getFieldConfig,
            getValue,
            setErrors,
            setValue,
        ]
    )

    // use boolean from option
    // when no options use boolean from props
    // when no props expect form to be new
    const errorFields = useMemo(
        () =>
            availableFields.filter((name) => {
                const errors = getErrors(name)
                return !!errors?.length
            }),
        [availableFields, getErrors]
    )

    // do we have changes (and if not new are they other than stored values)
    const hasChanges = useCallback(() => {
        // filter the fields that are not stored remote
        const filteredChanges = Object.keys(changes).filter(
            (name) => getFieldConfig(name)?.local !== true
        )

        return (
            !!Object.keys(filteredChanges).length &&
            filteredChanges.some((name) => changes[name] !== values?.[name])
        )
    }, [changes, getFieldConfig, values])

    // values the form will send to the api
    const getBody = useCallback(
        (extraValues) => {
            let body = {}

            // variables to send to api
            if (!isNew) {
                body = { ...changes, ...extraValues }
            } else {
                body = { ...values, ...changes, ...extraValues }
            }

            // remove local fields
            Object.keys(body).forEach((name) => {
                if (getFieldConfig(name)?.local === true) {
                    delete body[name]
                }
            })

            return body
        },
        [changes, getFieldConfig, isNew, values]
    )

    // the full form state
    const getValues = useCallback(() => {
        return { ...values, ...changes }
    }, [changes, values])

    const apiRequest = useCallback(
        async ({
            method,
            body,
            url,
        }: {
            method: 'POST' | 'PUT' | 'PATCH'
            url: string
            body?: any
        }) => {
            const result = await call({
                method,
                url,
                body,
            })
            setSaved(true)
            return result
        },
        []
    )

    const validate = useCallback(
        (fieldsToValidate?: string[]) => {
            const fields = fieldsToValidate || []
            return fields.every((name) => {
                const errors = getErrors(name)
                if (errors) {
                    console.warn(name, 'has error', errors)
                    return false
                }
                const field = getField(name)
                if (!field.isValid?.()) {
                    console.warn(name, 'is not valid', field)
                    return false
                }
                return true
            })
        },
        [getErrors, getField]
    )

    const isValid = useCallback(() => {
        return validate(availableFields || [])
    }, [availableFields, validate])

    const save = useCallback(
        async (extraValues, alternativeUrl): Promise<T> => {
            let result

            setSaving(true)

            if (!Object.keys({ ...changes, ...extraValues }).length) {
                if (isNew) {
                    console.warn(
                        'No changes detected. Did you await setValues?'
                    )
                } else {
                    console.warn(
                        'No changes detected. Did you await setValues? Well do a GET instead'
                    )
                    setSaving(false)
                    reexecute()
                    return result
                }
            }

            if (!isValid() && !extraValues) {
                console.error('Form is not valid')
                throw new Error('Form is not valid')
            }

            const body = getBody(extraValues)

            try {
                result = await apiRequest({
                    url: alternativeUrl || url,
                    method: !isNew ? 'PATCH' : 'POST',
                    body,
                })

                setSaving(false)
                setChanges({})

                // if url changed
                if (result.url && url !== result.url) {
                    setUrl(result.url)
                }
            } catch (response) {
                setSaving(false)

                if (response instanceof TypeError) {
                    setFormErrors((response && [response.message]) || null)
                    throw response
                }

                if (!(response instanceof Response)) {
                    console.warn('form hook error', url, body, response)
                    throw response
                }

                if (response.status >= 400) {
                    const clone = response.clone()
                    const { non_field_errors, ...fieldErrors } =
                        await clone.json()
                    setFormErrors(
                        non_field_errors ||
                            (fieldErrors.detail && [fieldErrors.detail]) ||
                            null
                    )
                    setFieldErrors(fieldErrors)
                    throw {
                        message: fieldErrors && fieldErrors.detail,
                        non_field_errors,
                        fieldErrors,
                    }
                }

                throw response
            }

            return result
        },
        [apiRequest, changes, getBody, isNew, isValid, reexecute, url]
    )

    return useMemo<Form<T>>(
        () => ({
            url,
            save,
            submit: save,
            isLoading,
            isSaving,
            isSaved,
            isReady,
            validate,
            isValid,
            isNew,
            availableFields,
            requiredFields,
            errorFields,
            getBody,
            getValues,
            getField,
            getValue,
            setUrl,
            setValue,
            getFields: (fieldsToGet) => fieldsToGet.map(getField),
            hasChanges,
            errors: formErrors,
            fieldErrors: fieldErrors,
            labels: props.labels,
        }),
        [
            availableFields,
            errorFields,
            fieldErrors,
            formErrors,
            getBody,
            getValues,
            getField,
            getValue,
            hasChanges,
            isLoading,
            isNew,
            isReady,
            isSaved,
            isSaving,
            isValid,
            props.labels,
            requiredFields,
            save,
            setValue,
            url,
            validate,
        ]
    )
}

export default useForm
