import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { v1 as uuid } from 'uuid'

import useModalClasses from '../hooks/modal'
import Loading from '../shared/components/suspense'
import Trans from '../shared/components/trans'
import CloseButton from '../shared/elements/close-button'
import { FormProps } from '../shared/form/hooked'

import '../../scss/react/modal/base.scss'

export interface ModalProps {
    modal: Modal
    onCloseModal: (force?: boolean) => void
    isFormModal?: boolean
}

interface ModalConfig {
    onClose?: () => void
    onRequestClose?: () => boolean
    className?: string
    closeButton?: boolean
    render?: (props: ModalProps) => React.ReactNode
    renderTitle?: (props: ModalProps) => React.ReactNode
    RenderForm?: (props: ModalProps) => JSX.Element
    isNew?: boolean
    isForm?: boolean
    title?: string
}

interface Modal extends ModalConfig {
    id: string
    open: boolean
}

export interface FormModalConfig extends ModalConfig {
    Form: JSX.Element | React.ReactNode | any
    url?: string // TODO need to move to values?
    values?: Record<string, any>
    title?: string
    item?: string
    onStartSaving?: FormProps['onStartSaving']
    onSaved?: FormProps['onSaved']
    onSave?: FormProps['onSave']
}

interface ModalContextProvider {
    openModal: (modal: ModalConfig) => Modal
    openFormModal: (modal: FormModalConfig) => void
    handleClose: (id?: Modal['id']) => void
    areOpenModals: boolean
    setTrayOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ModalContext = React.createContext<ModalContextProvider>(
    {} as ModalContextProvider
)
export default ModalContext

interface ModalComponentProps {
    modal: Modal
    onCloseModal: () => void
    onAfterClose: () => void
}

const ModalComponent = ({
    modal,
    onCloseModal,
    ...props
}: ModalComponentProps) => {
    const handleCloseModal = useCallback(
        (force) => {
            if (modal.onRequestClose && force !== true) {
                if (modal.onRequestClose()) {
                    onCloseModal()
                }
            } else {
                onCloseModal()
            }
        },
        [modal, onCloseModal]
    )

    useEffect(() => {
        window.onpopstate = handleCloseModal
    }, [handleCloseModal])

    return (
        <ReactModal
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            closeTimeoutMS={300}
            overlayClassName="hello-this-is-zupr"
            isOpen={modal.open}
            {...props}
            onRequestClose={handleCloseModal}
            className={classnames(modal.className, 'react-modal-base', {
                'modal-is-form': !!modal.RenderForm || !!modal.isForm,
            })}
        >
            <div className="modal-content">
                <Loading>
                    {!!modal.render &&
                        modal.render({
                            modal,
                            onCloseModal: handleCloseModal,
                        })}

                    {!!modal.RenderForm && (
                        <modal.RenderForm
                            isFormModal
                            onCloseModal={handleCloseModal}
                            modal={modal}
                            {...modal}
                        />
                    )}

                    {!!modal.renderTitle && (
                        <TitleModal
                            modal={modal}
                            onCloseModal={handleCloseModal}
                        />
                    )}
                </Loading>
                {modal.closeButton && (
                    <CloseButton onClick={handleCloseModal} />
                )}
            </div>
        </ReactModal>
    )
}

export const ModalProvider = ({ children }) => {
    const [modals, setModals] = useState<Modal[]>([])
    const [tray, setTrayOpen] = useState(false)

    // manage body class
    useModalClasses(modals.length > 0 || tray)

    useEffect(() => {
        ReactModal.setAppElement('#app')
    }, [])

    const openModal = useCallback((modal) => {
        if (!modal.id) {
            modal.id = uuid()
        }

        modal.open = true

        // add modal to array and delete all previous closed modals
        setModals((modals) => [...modals.filter((modal) => modal.open), modal])

        return modal
    }, [])

    const openFormModal = useCallback(
        ({ Form, ...props }) => {
            openModal({
                RenderForm: Form,
                ...props,
            })
        },
        [openModal]
    )

    const handleClose = useCallback((id) => {
        setModals((modals) =>
            modals.map((modal) => {
                if (modal.id === id && modal.open) {
                    modal.open = false
                    modal?.onClose()
                }
                return modal
            })
        )
    }, [])

    return (
        <ModalContext.Provider
            value={{
                openFormModal,
                openModal,
                handleClose,
                areOpenModals: !!modals.length,
                setTrayOpen,
            }}
        >
            {children}

            {modals.map((modal, i) => (
                <ModalComponent
                    modal={modal}
                    key={`modal.${i}`}
                    onCloseModal={() => handleClose(modal.id)}
                    onAfterClose={() =>
                        setModals((modals) =>
                            modals.filter(({ id }) => modal.id !== id)
                        )
                    }
                />
            ))}
        </ModalContext.Provider>
    )
}

const TitleModal = ({ modal, onCloseModal }: ModalProps) => {
    const { isNew, title, renderTitle } = modal

    return (
        <React.Fragment>
            <div
                className={classnames('modal-header', {
                    'header-success': isNew,
                })}
            >
                <h3>{title && <Trans label={title} />}</h3>
                <button
                    disabled={!modal.open}
                    type="button"
                    onClick={() => onCloseModal()}
                    className="btn btn-small"
                >
                    <Trans label="Close" />
                </button>
            </div>
            {renderTitle &&
                renderTitle({
                    modal,
                    onCloseModal,
                })}
        </React.Fragment>
    )
}

export const useFormModal = () => {
    const { openFormModal } = React.useContext(ModalContext)
    return openFormModal
}

export const useModal = () => {
    const { openModal } = React.useContext(ModalContext)
    return openModal
}
