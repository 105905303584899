import * as Sentry from '@sentry/nextjs'
import { toParams } from '@zupr/utils/url'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'

import { ParsedQuery } from 'query-string'
import DomainContext from './domain'

interface RouteProviderProps {
    children: React.ReactNode
    query?: ParsedQuery<string>
    params?: { [key: string]: string }
    referer?: string
}

type ChangeQuery = (kv: ParsedQuery<string>) => {
    pathname: string
    query: ParsedQuery<string>
}

type TrackEvent = ({
    id,
    action,
    view,
    nonInteraction,
    context,
}: {
    id?: string
    action: string
    view?: string
    nonInteraction?: boolean
    context?: string
}) => void

interface RouteContextProvider {
    query?: RouteProviderProps['query']
    params?: RouteProviderProps['params']
    referer?: RouteProviderProps['referer']
    changeQuery: ChangeQuery
    trackEvent: TrackEvent
}

export const RouteContext = React.createContext<RouteContextProvider>(
    {} as RouteContextProvider
)

export default RouteContext

const eventViews = [
    {
        path: '/winkel/:id/:slug(/*)(/*)',
        label: 'locationView',
    },
    {
        path: '/winkel/:id/:slug',
        label: 'locationView',
    },
    {
        path: '/winkel/:id',
        label: 'locationView',
    },
    {
        path: '/product/:id',
        label: 'productView',
    },
    {
        path: '/admin/location/:id',
        label: 'locationView',
    },
    {
        path: '/admin/product/:id',
        label: 'productView',
    },
]

export const RouteProvider = ({
    children,
    query,
    params,
    referer,
}: RouteProviderProps) => {
    const { type } = useContext(DomainContext)

    const router = useRouter()

    const pathname = router.asPath.split('?').shift().split('#').shift()

    // track pageviews
    useEffect(() => {
        const handleRouteChange = () => ReactGA.send('pageview')
        router.events.on('routeChangeComplete', handleRouteChange)
        return () => router.events.off('routeChangeComplete', handleRouteChange)
    }, [router.events])

    const changeQuery: ChangeQuery = useCallback(
        (kv) => {
            const newQuery = {
                ...query,
                offset: null,
                ...kv,
            }

            // remove empty keys
            Object.keys(newQuery).forEach((key) => {
                if (newQuery[key] === null || newQuery[key] === '') {
                    delete newQuery[key]
                }
            })

            // join arrays
            Object.keys(newQuery).forEach((key) => {
                if (Array.isArray(newQuery[key])) {
                    newQuery[key] = newQuery[key].join(',')
                }
            })

            return {
                pathname,
                query: newQuery,
            }
        },
        [query, pathname]
    )

    // track event to google analytics
    const trackEvent: TrackEvent = useCallback(
        ({ id, action, view, nonInteraction, context }) => {
            // match the page extract the view and id

            const match = eventViews
                .map((view) => {
                    const params = toParams(view.path, pathname)
                    return {
                        ...view,
                        params,
                        hasMatch: !!params,
                    }
                })
                .filter(({ hasMatch }) => hasMatch)
                .shift()

            let category = view || match?.label || null
            if (type === 'location' && !category) category = 'locationView'

            if (!category) {
                Sentry.captureMessage(
                    `Need an view to track this action: ${action} at: ${router.asPath}`
                )
            }

            if (!(id || match?.params.id)) {
                Sentry.captureMessage(
                    `Need an id to track this action: ${action} at: ${router.asPath}`
                )
            }

            ReactGA.set({
                dimension2: context || null,
            })

            ReactGA.event({
                category,
                action,
                label: id || match?.params.id || null,
                nonInteraction: nonInteraction || action.startsWith('auto'), // no user interaction
            })
        },
        [pathname, router.asPath, type]
    )

    return (
        <RouteContext.Provider
            value={{
                query,
                params,
                referer,
                changeQuery,
                trackEvent,
            }}
        >
            {children}
        </RouteContext.Provider>
    )
}

export const useGaEvent = (): TrackEvent => {
    const { trackEvent } = useContext(RouteContext)
    return trackEvent
}

export const useQuery = (): RouteProviderProps['query'] => {
    const { query } = useContext(RouteContext)
    return query
}

export const useParams = (): RouteProviderProps['params'] => {
    const { params } = useContext(RouteContext)
    return params
}

export const useHistory = (): RouteProviderProps['referer'] => {
    const { referer } = useContext(RouteContext)
    return referer
}

export const useHash = (): string => {
    const [hash, setHash] = useState<string>()

    const onHashChange = useCallback(() => {
        setHash(window.location.hash)
    }, [])

    useEffect(() => {
        window.onhashchange = onHashChange
    }, [onHashChange])

    return hash
}

export const useSearchParams = (): Readonly<URLSearchParams> => {
    const { query } = useRouter()
    const searchParams = new URLSearchParams()
    Object.keys(query).forEach((key) => {
        if (Array.isArray(query[key])) {
            query[key].forEach((value) => searchParams.append(key, value))
        } else {
            searchParams.append(key, query[key])
        }
    })
    return searchParams
}

export const usePathname = (): string => {
    const { pathname } = useRouter()
    return pathname
}
