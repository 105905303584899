import { useList } from '@zupr/hooks/request-redux'
import { useClickOutside } from '@zupr/hooks/ux'
import { t } from '@zupr/i18n'
import { Location, Product } from '@zupr/types/fo'
import { imageProviderHashToUrl } from '@zupr/utils/image'
import { locationUrl, patternToUrl } from '@zupr/utils/url'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useState } from 'react'

import RouteContext from '../../../context/route'
import Searchbox from '../../../shared/components/searchbox'
import Trans from '../../../shared/components/trans'
import SearchResult from '../../../shared/search/omni/item'
import NoResults from '../../../shared/search/omni/no-results'
import SearchSkeleton from '../../../shared/search/omni/skeleton'

import '../../../../scss/react/components/search.scss'

interface LocationSearchProps {
    onClick?: () => void
    location: Location
    dropdown?: boolean
}

const LocationSearch = React.forwardRef<HTMLInputElement, LocationSearchProps>(
    ({ onClick, location, dropdown = true }, ref) => {
        const { push, pathname } = useRouter()
        const { changeQuery } = useContext(RouteContext)
        const searchRef = React.createRef<HTMLDivElement>()

        const [search, setSearch] = useState('')
        const [focus, setFocus] = useState(false)

        const [products] = useList<Product>({
            url: 'fo/variation',
            variables: {
                search,
                limit: 10,
                'product_locations.location.id': location.id,
            },
            pause: !search,
        })

        const basePath = locationUrl(location, 'home')
        const productPath = `${basePath}/product/:id`

        const handleFocus = useCallback(() => {
            setFocus(true)
        }, [])

        const handleClose = useCallback(() => {
            setFocus(false)
        }, [])

        const handleClear = useCallback(() => {
            setSearch(undefined)
            handleClose()
        }, [handleClose])

        const handleGoToSearch = useCallback(() => {
            handleClear()
            if (onClick) onClick()
        }, [handleClear, onClick])

        const handleSearch = useCallback(
            (search) => {
                handleGoToSearch()

                const path = pathname.replace(/^\/|\/$/g, '').split('/')

                // user is at search page
                if (path.includes(basePath)) {
                    push(changeQuery({ search }))
                    return
                }

                push(`${locationUrl(location, 'products')}?search=${search}`)
            },
            [basePath, changeQuery, handleGoToSearch, location, pathname, push]
        )

        useClickOutside(searchRef, handleClose)

        const noResults = search && products?.count === 0

        return (
            <div ref={searchRef} className="omni-search-holder">
                <Searchbox
                    ref={ref}
                    placeholder="Search..."
                    value={search}
                    onFocus={handleFocus}
                    onChange={setSearch}
                    onEnter={handleSearch}
                    onClear={handleClear}
                    key={`omnisearch-${focus ? 'focus' : 'notfocus'}`}
                />
                {focus && noResults && (
                    <div
                        className={classNames({
                            'omni-search-dropdown': dropdown,
                        })}
                    >
                        <NoResults search={search} />
                    </div>
                )}
                {focus && search && !noResults && (
                    <div
                        className={classNames({
                            'omni-search-dropdown': dropdown,
                        })}
                    >
                        {(!products || products.count > 0) && (
                            <div className="omni-search-group">
                                <div className="omni-search-group-header">
                                    <Trans label="Products" />{' '}
                                    {products &&
                                        t('(%{count})', {
                                            count: products.count,
                                        })}
                                </div>
                                <ul>
                                    {!products &&
                                        [...Array(3)].map(() => (
                                            <SearchSkeleton subtitle />
                                        ))}
                                    {products?.results.map(
                                        ({ product_locations }) => (
                                            <SearchResult
                                                onClick={onClick}
                                                image={
                                                    product_locations[0].product
                                                        .images?.[0] &&
                                                    imageProviderHashToUrl(
                                                        {
                                                            id: product_locations[0]
                                                                .product
                                                                .images?.[0]
                                                                .image_provider_hash,
                                                        },
                                                        'webp',
                                                        'rs:fit:380:380/enlarge:1'
                                                    )
                                                }
                                                url={patternToUrl(productPath, {
                                                    id: product_locations[0]
                                                        .product.id,
                                                })}
                                                title={
                                                    product_locations[0].product
                                                        .title
                                                }
                                                subtitle={
                                                    product_locations[0].product
                                                        .brand?.title
                                                }
                                            />
                                        )
                                    )}
                                </ul>
                                <Link
                                    href={{
                                        pathname: basePath,
                                        query: { search },
                                    }}
                                >
                                    <a
                                        className="omni-search-more"
                                        onClick={handleGoToSearch}
                                    >
                                        <Trans label="View all products" />
                                    </a>
                                </Link>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
)

export default LocationSearch
