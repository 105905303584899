import { useContext } from 'react'
import { useMutation } from 'urql'

import { useForm } from '@zupr/form'
import { t } from '@zupr/i18n'
import { addEmailToList } from '@zupr/queries/shopping-area'

import AreaContext from '../../../../context/domain'

import Trans from '../../../../shared/components/trans'

import Row from '../../../../shared/form/elements/row'
import { GraphqlErrors } from '../../../../shared/form/errors'
import Check from '../../../../shared/form/fields/check'
import Email from '../../../../shared/form/fields/email'
import Input from '../../../../shared/form/fields/input'

import Checkmark from '../../../../../svg/checkmark.svg'

import '../../../../../scss/react/modal/box.scss'
import '../../../../../scss/react/modal/frontoffice-form.scss'

const NewsletterForm = ({ onCloseModal }) => {
    const { brand, shoppingAreaSlug } = useContext(AreaContext)

    const [mutation, executeMutation] = useMutation(addEmailToList)

    const ok = mutation.data?.addEmailToList?.ok
    const errors = mutation.data?.addEmailToList?.errors

    const form = useForm({
        pause: true,
        fields: {
            shoppingAreaSlug: {
                type: 'string',
                required: true,
            },
            name: {
                label: 'Name',
                required: true,
            },
            email: {
                label: 'Email',
                type: 'email',
                required: true,
            },
            terms: {
                required: true,
            },
        },
        values: {
            shoppingAreaSlug,
        },
    })

    const [name, email, terms] = form.getFields(['name', 'email', 'terms'])

    const handleSubit = async () => {
        await executeMutation(form.getBody())
    }

    if (ok) {
        return (
            <div className="modal-box">
                <div className="inner">
                    <div className="modal-frontoffice-success">
                        <div className="modal-frontoffice-success-checkmark">
                            <Checkmark />
                        </div>
                        <h3>{t('Bedankt voor uw aanmelding', 'dutch')}</h3>
                        <button
                            className="btn-link-inline"
                            onClick={onCloseModal}
                        >
                            {t('Close window')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="modal-box">
            <div className="inner">
                <div className="modal-frontoffice-form">
                    <h3>
                        {t('Ontvang de nieuwsbrief over %{brand}', 'dutch', {
                            brand,
                        })}
                    </h3>
                    <p>
                        {t(
                            'Meld je nu aan voor de nieuwsbrief en wij bezorgen het laatste nieuws over %{brand} rechtstreeks in je inbox.',
                            'dutch',
                            { brand }
                        )}
                    </p>
                    <Row field={name}>
                        <Input field={name} />
                    </Row>
                    <Row field={email}>
                        <Email field={email} />
                    </Row>

                    <Check field={terms}>
                        <Trans label="zupr-terms" markdown />
                    </Check>

                    <GraphqlErrors errors={errors} />

                    <button
                        disabled={!form.isValid()}
                        onClick={handleSubit}
                        className="btn btn-success"
                    >
                        {t('Aanmelden', 'dutch')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NewsletterForm
