import { useList } from '@zupr/hooks/request-redux'
import { t } from '@zupr/i18n'
import { Location, Product, SearchCategory } from '@zupr/types/fo'
import { imageProviderHashToUrl } from '@zupr/utils/image'
import { locationUrl, patternToUrl } from '@zupr/utils/url'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import ReactDOM from 'react-dom'

import AreaContext from '../../../context/domain'
import RouteContext from '../../../context/route'
import UxContext, { useIsMobile } from '../../../context/ux'
import usePortal from '../../../hooks/portal'
import Searchbox from '../../../shared/components/searchbox'
import Trans from '../../../shared/components/trans'
import SearchResult from '../../../shared/search/omni/item'
import SearchResultLink, {
    SearchResultLinkSkeleton,
} from '../../../shared/search/omni/link'
import NoResults from '../../../shared/search/omni/no-results'
import SearchSkeleton from '../../../shared/search/omni/skeleton'

import classNames from 'classnames'
import '../../../../scss/react/components/search.scss'

interface SearchProps {
    onClick?: () => void
    productsPath?: string
    locationsPath?: string
    productPath?: string
    filters?: Record<string, any>
}

interface ContentProps {
    search: string
    filters: Record<string, any>
    handleClose: () => void
    productPath: string
    productsPath: string
    handleGoToSearch: () => void
    locationsPath: string
    rect?: DOMRect
    dropdown?: boolean
}

const OmniSearchContent = React.forwardRef<HTMLDivElement, ContentProps>(
    (
        {
            search,
            filters,
            handleClose,
            productPath,
            productsPath,
            handleGoToSearch,
            locationsPath,
            rect,
            dropdown,
        },
        dropdownRef
    ) => {
        const { shoppingAreaSlug } = useContext(AreaContext)

        const [catergories] = useList<SearchCategory>({
            url: 'fo/category',
            variables: {
                search,
                limit: 5,
                shopping_area_slugs: shoppingAreaSlug,
                ...filters,
            },
            pause: !search,
        })

        const categoryParentsSlugs =
            catergories?.results
                .filter((category) => category.slug !== category.path)
                .map((category) => category.path.split('.').reverse()[1]) || []
        const [categoryParents] = useList<SearchCategory>({
            url: 'fo/category',
            variables: {
                slug__in: categoryParentsSlugs.join(','),
            },
            pause: !categoryParentsSlugs.length,
        })

        const getParentCategory = (
            path: SearchCategory['path']
        ): SearchCategory | undefined => {
            return categoryParents?.results?.find(
                (category) => category.slug === path.split('.').reverse()[1]
            )
        }

        const [locations] = useList<Location>({
            url: 'fo/location',
            variables: {
                search,
                limit: 3,
                shopping_areas: shoppingAreaSlug,
                ...filters,
            },
            pause: !search,
        })

        const [products] = useList<Product>({
            url: 'fo/variation',
            variables: {
                search,
                limit: 3,
                'product_locations.shopping_areas': shoppingAreaSlug,
                ...filters,
            },
            pause: !search,
        })

        const noResults =
            search &&
            locations &&
            products &&
            products.count === 0 &&
            locations.count === 0

        const style = useMemo(() => {
            if (!rect) return {}
            return {
                top: rect.top + rect.height + 10,
                left: rect.left,
                width: Math.max(rect.width, 300),
                transform: 'unset',
            }
        }, [rect])

        return (
            <div
                ref={dropdownRef}
                className={classNames({
                    'omni-search-dropdown': dropdown,
                })}
                style={{ ...style }}
            >
                {noResults && <NoResults search={search} />}
                {!noResults && (
                    <>
                        {(!catergories || catergories.count > 0) && (
                            <div className="omni-search-group">
                                <div className="omni-search-group-header">
                                    <Trans label="Product categories" />{' '}
                                    {catergories &&
                                        t('(%{count})', {
                                            count: catergories.count,
                                        })}
                                </div>
                                <ul>
                                    {!catergories &&
                                        [...Array(3)].map(() => (
                                            <SearchResultLinkSkeleton />
                                        ))}
                                    {catergories?.results.map(
                                        ({ name, slug, path }) => (
                                            <SearchResultLink
                                                key={slug}
                                                onClick={handleClose}
                                                title={name}
                                                subtitle={
                                                    !!getParentCategory(path) &&
                                                    `in ${
                                                        getParentCategory(path)
                                                            ?.name
                                                    }`
                                                }
                                                url={`${productsPath}/${slug}`}
                                            />
                                        )
                                    )}
                                </ul>
                            </div>
                        )}

                        {(!products || products.count > 0) && (
                            <div className="omni-search-group">
                                <div className="omni-search-group-header">
                                    <Trans label="Products" />{' '}
                                    {products &&
                                        t('(%{count})', {
                                            count: products.count,
                                        })}
                                </div>
                                <ul>
                                    {!products &&
                                        [...Array(3)].map(() => (
                                            <SearchSkeleton subtitle />
                                        ))}
                                    {products?.results.map(
                                        ({ product_locations }) => (
                                            <SearchResult
                                                onClick={handleClose}
                                                image={
                                                    product_locations[0].product
                                                        .images?.[0] &&
                                                    imageProviderHashToUrl(
                                                        {
                                                            id: product_locations[0]
                                                                .product
                                                                .images?.[0]
                                                                .image_provider_hash,
                                                        },
                                                        'webp',
                                                        'rs:fit:380:380/enlarge:1'
                                                    )
                                                }
                                                url={patternToUrl(
                                                    productPath,
                                                    product_locations[0].product
                                                )}
                                                title={
                                                    product_locations[0].product
                                                        .title
                                                }
                                                subtitle={
                                                    product_locations[0].product
                                                        .brand?.title
                                                }
                                            />
                                        )
                                    )}
                                </ul>
                                <Link
                                    href={{
                                        pathname: productsPath,
                                        query: { search },
                                    }}
                                >
                                    <a
                                        className="omni-search-more"
                                        onClick={handleGoToSearch}
                                    >
                                        <Trans label="View all products" />
                                    </a>
                                </Link>
                            </div>
                        )}
                        {(!locations || locations.count > 0) && (
                            <div className="omni-search-group shops">
                                <div className="omni-search-group-header">
                                    <Trans label="Shops" />{' '}
                                    {locations &&
                                        t('(%{count})', {
                                            count: locations.count,
                                        })}
                                </div>
                                <ul>
                                    {!locations &&
                                        [...Array(3)].map(() => (
                                            <SearchSkeleton />
                                        ))}
                                    {locations &&
                                        locations.results.map((location) => (
                                            <li>
                                                <SearchResult
                                                    onClick={handleClose}
                                                    image={
                                                        !!location.images
                                                            .length &&
                                                        imageProviderHashToUrl(
                                                            {
                                                                id: location
                                                                    .images[0]
                                                                    .image_provider_hash,
                                                            },
                                                            'webp',
                                                            'rs:fit:380:380/enlarge:1'
                                                        )
                                                    }
                                                    url={locationUrl(
                                                        location,
                                                        'home'
                                                    )}
                                                    title={location.title}
                                                    subtitle={`${location.address}, ${location.city}`}
                                                />
                                            </li>
                                        ))}
                                </ul>
                                <Link
                                    href={{
                                        pathname: locationsPath,
                                        query: { search },
                                    }}
                                >
                                    <a
                                        className="omni-search-more"
                                        onClick={handleGoToSearch}
                                    >
                                        <Trans label="View all shops" />
                                    </a>
                                </Link>
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }
)

const OmniSearch = React.forwardRef<HTMLInputElement, SearchProps>(
    (
        {
            onClick,
            productsPath = '/products',
            locationsPath = '/locations',
            productPath = '/products',
            filters = {},
        },
        ref
    ) => {
        const { push, pathname } = useRouter()
        const { changeQuery } = useContext(RouteContext)
        const { search, setSearch } = useContext(UxContext)
        const isMobile = useIsMobile()
        const [rect, setRect] = useState<DOMRect>(null)

        const portalTarget = usePortal('omni-search-portal')

        const holderRef = useRef<HTMLDivElement>(null)
        const dropdownRef = useRef<HTMLDivElement>(null)

        const [focus, setFocus] = useState(false)

        const handleFocus = useCallback(() => {
            setFocus(true)
        }, [])

        const handleClose = useCallback(() => {
            setFocus(false)
            onClick?.()
        }, [onClick])

        const handleClear = useCallback(() => {
            setSearch(null)
            handleClose()
        }, [handleClose, setSearch])

        const handleGoToSearch = useCallback(() => {
            handleClear()
            if (onClick) onClick()
        }, [handleClear, onClick])

        const handleSearch = useCallback(
            (search) => {
                handleGoToSearch()

                const path = pathname.replace(/^\/|\/$/g, '').split('/')

                // user is at search page
                if (
                    path.includes(productsPath) ||
                    path.includes(locationsPath)
                ) {
                    push(changeQuery({ search }))
                    return
                }
                push(`${productsPath}?search=${search}`)
            },
            [
                changeQuery,
                handleGoToSearch,
                locationsPath,
                pathname,
                productsPath,
                push,
            ]
        )

        // add resize listener
        useEffect(() => {
            if (!holderRef.current) return
            const rect = holderRef.current.getBoundingClientRect()
            setRect(rect)

            const handleResize = () => {
                if (holderRef.current) {
                    const rect = holderRef.current.getBoundingClientRect()
                    setRect(rect)
                }
            }

            window.addEventListener('resize', handleResize)
            setTimeout(() => handleResize(), 100)
            return () => window.removeEventListener('resize', handleResize)
        }, [])

        // add click outside listener
        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (!dropdownRef.current) return
                if (!holderRef.current) return
                if (dropdownRef.current.contains(event.target as Node)) return
                if (holderRef.current.contains(event.target as Node)) return
                handleClose()
            }
            window.addEventListener('click', handleClickOutside)
            return () => window.removeEventListener('click', handleClickOutside)
        }, [handleClose])

        return (
            <div className="omni-search-holder" ref={holderRef}>
                <Searchbox
                    ref={ref}
                    placeholder="Search..."
                    value={search}
                    onFocus={handleFocus}
                    onChange={setSearch}
                    onEnter={handleSearch}
                    onClear={handleClear}
                />
                {focus &&
                    search &&
                    !isMobile &&
                    ReactDOM.createPortal(
                        <OmniSearchContent
                            ref={dropdownRef}
                            rect={rect}
                            filters={filters}
                            search={search}
                            handleClose={handleClose}
                            productPath={productPath}
                            productsPath={productsPath}
                            handleGoToSearch={handleGoToSearch}
                            locationsPath={locationsPath}
                            dropdown
                        />,
                        portalTarget
                    )}
                {focus && search && isMobile && (
                    <OmniSearchContent
                        ref={dropdownRef}
                        filters={filters}
                        search={search}
                        handleClose={handleClose}
                        productPath={productPath}
                        productsPath={productsPath}
                        handleGoToSearch={handleGoToSearch}
                        locationsPath={locationsPath}
                    />
                )}
            </div>
        )
    }
)

export default OmniSearch
