import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import RouteContext from '../context/route'
import { useShopperLocation } from '../context/shopper'

// adds bounding box to the search query
export const useBoundingBox = (filterKey: string) => {
    const { query, push } = useRouter()
    const { changeQuery } = useContext(RouteContext)

    const { box } = useShopperLocation()
    // add use location filter
    useEffect(() => {
        if (!box) return // no shopper position
        if (query[filterKey] === 'false') return // do not re-add filter
        if (box === query[filterKey]) return
        push(
            changeQuery({
                box,
            })
        )
    }, [box, changeQuery, filterKey, push, query])
}
