import { useClickOutside, useEscapeKey } from '@zupr/hooks/ux'
import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
} from 'body-scroll-lock-upgrade'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

import { useIsMobile } from '../../context/ux'

import '../../../scss/figma/dialog.scss'

interface ModalDialogProps {
    isOpen: boolean
    onClose: () => void
    children: React.ReactNode
    className?: string
    position: 'left' | 'right' | 'center' | 'top'
}

const ModalDialog: React.FC<ModalDialogProps> = ({
    isOpen,
    onClose,
    children,
    className,
    position = 'center',
}) => {
    const [isFirstOpened, setIsFirstOpened] = React.useState(false)
    const dialogRef = useRef<HTMLDialogElement | null>(null)
    const modalContentRef = useRef<HTMLDivElement | null>(null) // Reference for modal content
    const bodyRef = useRef<HTMLElement | null>(null) // Ref to store document.body

    const isMobile = useIsMobile()

    // Use the custom hook for the Escape key handling
    useEscapeKey(() => isOpen && onClose())
    useClickOutside(modalContentRef, onClose)

    useEffect(() => {
        if (!dialogRef.current) return
        if (!isFirstOpened) return
        if (isOpen) {
            disableBodyScroll(dialogRef.current, {
                reserveScrollBarGap: true,
                allowTouchMove: (el) =>
                    !!modalContentRef.current?.contains(el as Node),
            })

            dialogRef.current.showModal()
            dialogRef.current.classList.add('dialog-open-animation')
            dialogRef.current.addEventListener(
                'animationend',
                () =>
                    dialogRef.current?.classList.remove(
                        'dialog-open-animation'
                    ),
                { once: true }
            )
        } else {
            dialogRef.current.classList.add('dialog-close-animation')
            dialogRef.current.addEventListener(
                'animationend',
                () => {
                    dialogRef.current?.close()
                    dialogRef.current?.classList.remove(
                        'dialog-close-animation'
                    )
                    clearAllBodyScrollLocks()
                },
                { once: true }
            )
        }
    }, [isOpen, isFirstOpened])

    useEffect(() => {
        if (isOpen) {
            setIsFirstOpened(true)
        }
    }, [isOpen])

    // Use effect to set the bodyRef once the component is mounted
    useEffect(() => {
        bodyRef.current = document.body // Store document.body in ref
        return () => {
            bodyRef.current = null // Cleanup when component unmounts
        }
    }, [])

    // Ensure the modal is only rendered on the client-side
    if (!bodyRef.current) return null
    if (!isFirstOpened) return null

    return ReactDOM.createPortal(
        <dialog
            ref={dialogRef}
            onClose={onClose}
            className={classNames(className, {
                'dialog-right':
                    position === 'right' || (position === 'center' && isMobile),
                'dialog-left': position === 'left',
                'dialog-top': position === 'top',
            })}
        >
            <div className="dialog-content" ref={modalContentRef}>
                <FocusLock>{children}</FocusLock>
            </div>
        </dialog>,
        bodyRef.current
    )
}

export default ModalDialog
