import { t } from '@zupr/i18n'
import { useCallback, useContext, useState } from 'react'
import { DomainContext } from '../../../../context/domain'

import ModalDialog from '../../../../shared/components/dialog'
import LocationSearch from '../../search/location'
import ShoppingAreaSearch from '../../search/shopping-area'

import CloseIcon from '../../../../../svg/cross.svg'
import SearchIcon from '../../../../../svg/search.svg'

const MobileSearchButton = () => {
    const { type, location } = useContext(DomainContext)

    const [open, setOpen] = useState(false)

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
            <button
                className="inline"
                aria-label={t('Search')}
                onClick={handleOpen}
            >
                <span>
                    <SearchIcon />
                </span>
            </button>
            <ModalDialog isOpen={open} onClose={handleClose} position="right">
                <div className="dialog-header">
                    <h3>{t('Search')}</h3>
                    <button
                        className="btn btn-micro btn-light menu-close"
                        type="button"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div className="dialog-form">
                    {type === 'area' && (
                        <ShoppingAreaSearch onClick={handleClose} />
                    )}
                    {type === 'location' && (
                        <LocationSearch
                            location={location}
                            onClick={handleClose}
                            dropdown={false}
                        />
                    )}
                </div>
            </ModalDialog>
        </>
    )
}

export default MobileSearchButton
